import '@/assets/scss/app.scss'
import "animate.css"

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Nl2br from 'vue-nl2br'

Vue.config.productionTip = false

import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

import TranslateService from './services/TranslateService'
const ServicesPlugin = {
  install(Vue) {
    Vue.prototype.$t = TranslateService
  }
}
Vue.use(ServicesPlugin)
Vue.component('nl2br', Nl2br)

if (!localStorage.getItem('lang')) {
  localStorage.setItem('lang', 'pt')
}

import carousel from 'vue-owl-carousel'
Vue.component('carousel', carousel)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
