<template>
	<div class="footer">
		<b-container>
			<b-row align-v="center">
				<b-col>
					<div class="box-info">
						<span>(51) 3559-3282 | 99589-0690</span>
						<span>R. Plácido de Castro, 28 - Sete de Setembro, Sapiranga - RS, 93819-252</span>
						<span>wellvin@wellvin.com.br</span>
					</div>
				</b-col>

				<b-col cols="auto">
					<div class="box-social">
						<base-social-links />
					</div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import BaseSocialLinks from './BaseSocialLinks.vue'
export default {
	components: { BaseSocialLinks },
	data() {
		return {}
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';
@import '~bootstrap/scss/bootstrap';

.footer {
	color: #fff;
	background: #000;
	padding: 3rem 0;
	margin-top: 12rem;

	.box-info {
		span {
			display: block;
		}
	}

	.box-social {
		a {
			color: #fff;
			text-decoration: none;

			&:hover {
				color: $primary;
				span {
					text-decoration: underline;
				}
			}
		}
	}

	@include media-breakpoint-down(sm) {
	}
}
</style>
