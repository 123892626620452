<template>
	<div id="nav">
		<router-link to="/">Home</router-link>
		<router-link to="/sobre">Wellvin</router-link>
		<router-link to="/produtos">Produtos</router-link>
		<router-link to="/orcamento">
			<span>Orçamento</span>
			<span class="qty">{{ budgetCount }}</span>
		</router-link>
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	computed: {
		budgetCount() {
			return this.$store.getters.budgetCount
		}
	}
}
</script>

<style lang="scss">
#nav {
	a {
		color: #fff;
		text-transform: uppercase;
		padding: 1rem 2rem;
		display: inline-flex;
		align-items: center;

		&.router-link-exact-active {
			color: #ffe400;
		}
	}

	.qty {
		width: 2.8rem;
		height: 2.8rem;
		background-color: #fff;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		font-size: 1.4rem;
		margin-left: 0.5rem;
		color: $primary;
		font-weight: 600;
	}
}
</style>
