<template>
	<div class="social-links">
		<a target="_blank" href="https://www.instagram.com/wellvinalimentos/">
			<i class="la la-instagram"></i>
			<span v-if="!hideLinks">@wellvinalimentos</span>
		</a>
		<a target="_blank" href="https://fr-fr.facebook.com/wellvinalimentos">
			<i class="la la-facebook"></i>
			<span v-if="!hideLinks">wellvinalimentos</span>
		</a>
	</div>
</template>

<script>
export default {
	props: {
		hideLinks: Boolean
	}
}
</script>

<style lang="scss">
.social-links {
	a {
		display: inline-flex;
		align-items: center;

		+ a {
			margin-left: 1rem;
		}

		i {
			font-size: 3rem;
			+ span {
				margin-left: 0.5rem;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		margin-top: 2rem;
	}
}
</style>
