<template>
	<div class="banner-text">
		<carousel :items="1" :nav="false">
			<div v-for="item in 1" :key="item" :style="{ backgroundImage: `url(${getImage()})` }">
				<div class="banner-text-box">
					<p>A Wellvin tem o que é bom <br />para você e sua família.</p>
					<b-btn variant="primary" :to="{ name: 'about' }"> conheça </b-btn>
				</div>
			</div>
		</carousel>
	</div>
</template>

<script>
export default {
	name: 'BaseBannerText',
	components: {},

	data: () => ({}),

	methods: {
		getImage() {
			return require(`@/assets/images/banner-home-1.jpg`)
		}
	}
}
</script>

<style lang="scss">
.banner-text {
	.banner-text-box {
		font-size: 4.2rem;
		text-align: center;
		padding: 16rem 0;
		line-height: 1.2;
		color: #fff;
		display: flex;
		flex-direction: column;

		p {
			text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
			font-weight: 600;
		}

		.btn {
			margin: 2rem auto;
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-down(sm) {
		.banner-text-box {
			font-size: 3rem;
		}
	}
}
</style>
