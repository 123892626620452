import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/produtos',
    name: 'product.list',
    meta: {
      title: 'Produtos'
    },
    component: () => import(/* webpackChunkName: "ProductList" */ '../views/ProductList.vue')
  },
  {
    path: '/produtos/:id',
    name: 'product.detail',
    meta: {
      title: 'Produto'
    },
    component: () => import(/* webpackChunkName: "ProductDetail" */ '../views/ProductDetail.vue')
  },
  {
    path: '/sobre',
    name: 'about',
    meta: {
      title: 'Sobre'
    },
    component: () => import(/* webpackChunkName: "About" */ '../views/About.vue')
  },
  {
    path: '/orcamento',
    name: 'contact',
    meta: {
      title: 'Contato'
    },
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  if (to.meta.title) {
    document.title = `${to.meta.title} ~ Wellvin`
  } else {
    document.title = 'Wellvin ~ Sabor e Saúde'
  }

  if (from.name) {
    setTimeout(() => {
      // const elmnt = document.getElementById("main");
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: 600
      })
    }, 500)
  }
  next()
});

export default router
