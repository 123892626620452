<template>
	<div class="title-text-center">
		<h2 class="title-text-center-title">Mais de duas décadas de qualidade e comprometimento</h2>

		<p class="title-text-center-text">
			Com todos esses anos de experiência no mercado, você pode ter a confiança de que oferecemos sempre os melhores
			produtos, com alto padrão de controle de qualidade e uma postura focada em criar laços duradouros com nossos
			clientes.
		</p>

		<div class="text-center">
			<b-button variant="primary" :to="{ name: 'about' }"> Saiba mais </b-button>
		</div>
	</div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.title-text-center {
	margin: 12rem 0;
	.title-text-center-title {
		font-size: 5rem;
	}
	.title-text-center-text {
		max-width: 640px;
		margin: 5rem auto;
		font-size: 2rem;
	}

	@include media-breakpoint-down(sm) {
		.title-text-center-title {
			font-size: 4rem;
		}
	}
}
</style>
