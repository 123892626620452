<template>
	<div class="home">
		<div class="home-content">
			<b-container>
				<base-title-text-center />
			</b-container>
			<base-banner-text />
			<base-catalogo-slider />
			<b-container>
				<base-realeases-slider />
				<base-budget />
			</b-container>
		</div>
	</div>
</template>

<script>
import BaseBannerText from '../components/BaseBannerText.vue'
import BaseBudget from '../components/BaseBudget.vue'
import BaseCatalogoSlider from '../components/BaseCatalogoSlider.vue'
import BaseRealeasesSlider from '../components/BaseProductsSlider.vue'
import BaseTitleTextCenter from '../components/BaseTitleTextCenter.vue'
export default {
	components: { BaseTitleTextCenter, BaseBannerText, BaseCatalogoSlider, BaseRealeasesSlider, BaseBudget }
}
</script>
 