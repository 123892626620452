import axios from 'axios'
import store from '@/store';

class HttpRequest {
  constructor() {
    this.xhtCount = 0
    this.loader = true

    this.xhr = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      timeout: 30000,
    })

    this.xhr.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
      window.headers = {
        Authorization: config.headers.Authorization
      }

      this.xhtCount++

      if (this.loader) {
        store.commit('SET_SHOW_LOADER')
      }

      return config
    })

    this.xhr.interceptors.response.use(response => {
      this.checkLoading()

      return response;
    }, (error) => {
      this.checkLoading()
      console.log('HttpRequest', error);

      // if (error.response.status === 404) {
      //   store.dispatch('notFound', true)
      // }

      if (error.response.status === 401) {
        // store.dispatch('logout')
      }

      return Promise.reject(error.response);
    });
  }

  checkLoading() {
    this.loader = true

    this.xhtCount--

    if (this.xhtCount <= 0) {
      this.xhtCount = 0

      setTimeout(() => {
        if (this.xhtCount == 0) {
          store.commit('SET_HIDE_LOADER')
        }
      }, 200)

    }
  }

  noLoader(loading = false) {
    this.loader = loading
  }

  async get(uri, params = {}) {
    params._ = new Date().getTime()
    const res = await this.xhr.get(uri, { params: params })
    return res.data
  }

  async put(uri, data) {
    const res = await this.xhr.put(uri, data)
    return res.data
  }

  async post(uri, data, params = {}) {
    const res = await this.xhr.post(uri, data, { params: params })
    return res.data
  }

  async delete(uri, data) {
    const res = await this.xhr.delete(uri, { data: data })
    return res.data
  }
}

export default HttpRequest
