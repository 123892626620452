<template>
	<div class="header" :class="{ 'menu-open': menuOpen }">
		<div class="header-top">
			<b-container>
				<div class="header-content">
					<span class="box-white"></span>
					<b-link :to="{ name: 'home' }" class="header-logo">
						<img src="@/assets/images/logo.png" alt="Wellvin" />
					</b-link>

					<div class="btn-menu" @click="menuOpen = !menuOpen">
						<i class="las la-bars" v-show="!menuOpen"></i>
						<i class="las la-times" v-show="menuOpen"></i>
					</div>
					<div class="navigation">
						<div class="header-menus">
							<base-header-nav />
						</div>
						<div class="header-social-link">
							<base-social-links hideLinks />
						</div>
					</div>
				</div>
			</b-container>
		</div>
		<base-banner />
	</div>
</template>

<script>
import BaseBanner from './BaseBanner.vue'
import BaseHeaderNav from './BaseHeaderNav.vue'
import BaseSocialLinks from './BaseSocialLinks.vue'
export default {
	components: { BaseBanner, BaseHeaderNav, BaseSocialLinks },
	data() {
		return {
			open: false,
			menuOpen: false
		}
	},
	methods: {},
	watch: {
		$route() {
			this.menuOpen = false
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

.header {
	&.menu-open {
		.navigation {
			display: flex;
		}
	}

	.header-top {
		background-color: $primary;
		position: relative;
		z-index: 88;
	}
	.header-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		height: 15rem;

		&:before {
			content: '';
			background: #fff;
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 10rem;
			top: 0;
			z-index: 1;
			transform: translateX(-100%);
		}
	}

	.header-logo {
		position: relative;

		img {
			position: relative;
			z-index: 1;
			transform: translateY(2rem);
		}
	}

	.box-white {
		height: 100%;
		width: 100%;
		display: block;
		position: absolute;
		border-radius: 100px;
		top: 0;
		z-index: -1;
		overflow: hidden;

		&:before {
			content: '';
			background: #fff;
			display: block;
			position: absolute;
			border-radius: 30rem;
			width: 22rem;
			height: 22rem;
			left: 0;
			top: -1.8rem;
			z-index: 1;
		}
	}

	.header-social-link {
		a {
			color: #fff;
			border: 2px solid #fff;
			border-radius: 100%;
			width: 4.6rem;
			height: 4.6rem;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			transition: all 0.2s;

			i {
				font-size: 2.4rem;
			}

			&:hover {
				text-decoration: none;
				background-color: #fff;
				color: $primary;
			}
		}
	}
	.navigation {
		display: flex;
		flex-grow: 1;
		justify-content: center;
		.header-menus {
			padding-left: 8rem;
		}

		.header-social-link {
			margin-left: auto;
		}
	}

	.btn-menu {
		color: #fff;
		font-size: 3rem;
		height: 100%;
		padding: 1rem;
		display: none;
	}

	@include media-breakpoint-down(sm) {
		.btn-menu {
			display: block;
		}
		.header-logo {
			img {
				max-width: 9rem;
			}
		}

		.navigation {
			display: none;
			position: absolute;
			background-color: $primary;
			top: 9rem;
			left: -2rem;
			right: -2rem;
			flex-direction: column;
			align-items: center;
			padding: 3rem 0;

			.header-menus {
				padding: 0;
			}

			.header-social-link {
				margin-left: 0;
			}

			#nav {
				display: flex;
				flex-direction: column;
				a {
					justify-content: center;
					height: 5rem;
				}
			}
		}

		.header-content {
			height: auto;
		}
	}
}
</style>
