<template>
	<div class="banner">
		<carousel :items="1" :nav="false" autoplay class="owl-banner">
			<div class="banner-item" :style="{ backgroundImage: `url(${getImage(1)})`, backgroundPosition: 'center' }">
				<div class="banner-item-text" style="color: #134e78">
					Tradição
					<br />e qualidade
					<br />
					<span style="color: #ff0300"> na sua mesa. </span>
				</div>
			</div>
			<div class="banner-item" :style="{ backgroundImage: `url(${getImage(2)})`, backgroundPosition: 'center' }">
				<div class="banner-item-text text-shadow" style="color: #fff">
					Chás clássicos,
					<br />
					<span style="color: #ffcc00">e especiais. </span>
					<div class="banner-item-see">
						<b-btn variant="primary" :to="{ name: 'product.list', query: { cat: 7 } }">Conheça</b-btn>
					</div>
				</div>
			</div>
			<div
				class="banner-item banner-item3"
				:style="{ backgroundImage: `url(${getImage(3)})`, backgroundPosition: 'center' }"
			>
				<div class="banner-item-text text-shadow" style="color: #fff">
					Farinhas,
					<br />
					<span style="color: #ffcc00"> cereais e grãos. </span>
				</div>
			</div>
			<div
				class="banner-item banner-item4"
				:style="{ backgroundImage: `url(${getImage(4)})`, backgroundPosition: 'center' }"
			>
				<div class="banner-item-text text-shadow" style="color: #fff">
					Confira o nosso
					<br />
					<span style="color: #ffcc00">catálogo. </span>
					<div class="banner-item-see">
						<b-btn variant="primary" href="./assets/Wellvin-Catálogo-Páginas-WEB.pdf" target="_blank">Conheça</b-btn>
					</div>
				</div>
			</div>
		</carousel>
	</div>
</template>

<script>
export default {
	name: 'BaseBanner',
	components: {},

	data() {
		return {}
	},

	methods: {
		getImage(item) {
			let mobile = ''
			if (window.innerWidth <= 800) {
				mobile = '-m'
			}
			return require(`@/assets/images/banner-${item}${mobile}.jpg`)
		}
	}
}
</script>

<style lang="scss">
.banner {
	.banner-item {
		min-height: 49.8rem;
		display: flex;
		align-items: center;
		line-height: 1.1;
		font-weight: 600;
		justify-content: flex-start;
		background-repeat: no-repeat;
		.banner-item-text {
			font-size: 7rem;
			@extend .container;

			&.text-shadow {
				text-shadow: 0 2px 2px rgba(0, 0, 0, 0.9);
			}
		}

		.banner-item-see {
			max-width: 30rem;
			width: 100%;
			text-align: right;
			position: relative;
			&:before {
				content: '';
				height: 2px;
				background-color: $primary;
				width: 50%;
				position: absolute;
				left: 0;
				top: 50%;
				z-index: 0;
			}
			.btn {
				position: relative;
				z-index: 99;
			}
		}
	}

	@include media-breakpoint-down(sm) {
		.banner-item {
			min-height: 60rem;
			.banner-item-text {
				font-size: 4.4rem;
				line-height: 1;
				padding-left: 3rem;
			}

			&.banner-item3 {
				.banner-item-text {
					position: absolute;
					top: 10rem;
				}
			}

			&.banner-item4 {
				.banner-item-text {
					margin-top: 5rem;
				}
			}
		}
	}
}
</style>
