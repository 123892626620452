import Vue from 'vue'
import Vuex from 'vuex'
import { ToastPlugin } from 'bootstrap-vue'

Vue.use(Vuex)
Vue.use(ToastPlugin)

export default new Vuex.Store({
  state: {
    isLoading: false,
    cart: {},
    budgetCount: 0
  },
  mutations: {
    SET_SHOW_LOADER: state => state.isLoading = true,
    SET_HIDE_LOADER: state => state.isLoading = false,
    SET_BUDGET_COUNT: (state, payload) => state.budgetCount = payload,
    SET_CART: (state, payload) => {
      localStorage.setItem('cart', JSON.stringify(payload))
      state.cart = payload

      let count = 0
      Object.keys(state.cart).map(index => {
        count = (count * 1 + state.cart[index].qty * 1)
      })

      state.budgetCount = count
    }
  },
  getters: {
    isLoading: state => state.isLoading,
    budgetCount: state => state.budgetCount,
    cart: state => state.cart,

  },
  actions: {
    addToCart: (context, payload) => {
      let cart = context.state.cart
      const id = `item_${payload.id}_${payload.variant}`

      if (cart[id]) {
        cart[id].qty = cart[id].qty + 1
      } else {
        cart[id] = { id: payload.id, qty: payload.qty || 1, variant: payload.variant, name: payload.name }
      }

      context.commit('SET_CART', cart)
    },
    getCart(context) {
      const cart = JSON.parse(localStorage.getItem('cart')) || {}
      context.commit('SET_CART', cart)
    }
  },
  modules: {
  }
})
