<template>
	<div class="budget">
		<b-row align-v="center">
			<b-col>
				<h2 class="budget-title">Faça seu orçamento</h2>
				<p class="budget-text">
					Selecione produtos em nosso catálogo e entre em contato para solicitar um orçamento e tirar suas dúvidas.
				</p>
				<b-btn variant="primary" :to="{ name: 'contact' }"> Fale conosco </b-btn>
			</b-col>
			<b-col>
				<img src="@/assets/images/texto-orcamento.jpg" class="img-fluid rounded" />
			</b-col>
		</b-row>
	</div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.budget {
	margin: 8rem 0;

	.budget-title {
		margin-bottom: 2rem;
		font-weight: 700;
		font-size: 3rem;
	}
	.budget-text {
		max-width: 400px;
	}
	.btn {
		margin-top: 2rem;
	}
}
</style>
