import ApiService from './ApiService'

export default {
  getNews() {
    const api = new ApiService()
    return api.get('/products', {
      limit: 6
    })
  },
  get(params) {
    params = {
      ...{ limit: 999 },
      ...params
    }
    const api = new ApiService()
    return api.get('/products', params)
  },
  getOne(id) {
    const api = new ApiService()
    return api.get(`/products/${id}`)
  }
}
