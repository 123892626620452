<template>
	<div class="releases-slider">
		<h3 class="releases-slider-title">Lançamentos</h3>

		<carousel
			:items="3"
			:nav="false"
			:dots="false"
			class="releases-slider-box"
			:responsive="{ 0: { items: 2 }, 600: { items: 3 } }"
			v-if="rows.length"
		>
			<div class="releases-slider-item" v-for="item in rows" :key="item.id">
				<b-img v-if="item.images.length" :src="item.images[0]" fluid :alt="item.name"></b-img>
				<div class="releases-slider-item-title">{{ item.name }}</div>
				<div class="releases-slider-item-subtitle" v-if="item.tags">
					{{ item.tags.join(', ') }}
				</div>
				<b-btn variant="primary" :to="{ name: 'product.detail', params: { id: item.id } }">Destaque</b-btn>
			</div>

			<template #prev>
				<span class="nav-btn nav-btn-left">
					<i class="las la-angle-left"></i>
				</span>
			</template>
			<template #next>
				<span class="nav-btn nav-btn-right">
					<i class="las la-angle-right"></i>
				</span>
			</template>
		</carousel>
	</div>
</template>

<script>
import ProductService from '@/services/ProductService.js'
export default {
	data: () => ({
		rows: []
	}),
	mounted() {
		this.init()
	},
	methods: {
		async init() {
			const data = await ProductService.getNews()
			console.log(data)
			this.rows = data.data
		}
	}
}
</script>

<style lang="scss">
.releases-slider {
	text-align: center;
	margin: 12rem 0;

	.releases-slider-title {
		color: $primary;
		font-size: 3rem;
	}

	.releases-slider-box {
		margin-top: 2rem;
		position: relative;
		.nav-btn {
			position: absolute;
			top: 50%;
			font-size: 3rem;
			transform: translateY(-4rem);
			z-index: 99;
			cursor: pointer;

			&:hover {
				color: $primary;
			}
		}
		.nav-btn-left {
			left: 0;
		}
		.nav-btn-right {
			right: 0;
		}
	}

	.releases-slider-item {
		padding: 4rem;

		img {
			padding: 20px;
		}

		.releases-slider-item-title {
			font-size: 1.8rem;
			font-weight: 600;
			line-height: 1.1;
			margin-top: 1.5rem;
			min-height: 4rem;
		}
		.releases-slider-item-subtitle {
			color: $text-muted;
		}
		.btn {
			margin-top: 2rem;
		}
	}

	@include media-breakpoint-down(sm) {
		.releases-slider-item {
			padding: 2rem;
		}
	}
}
</style>
