<template>
	<div class="catalog-slider">
		<h4 class="catalog-slider-title">
			<a href="./assets/Wellvin-Catálogo-Páginas-WEB.pdf" target="_blank">Conheça nosso catálogo de produtos</a>
		</h4>
		<b-container>
			<carousel
				:items="3"
				:nav="false"
				:dots="false"
				class="catalog-slider-box"
				:responsive="{ 0: { items: 2 }, 600: { items: 3 } }"
			>
				<router-link
					class="cataglo-slide-item"
					v-for="item in items"
					:key="item.name"
					:to="{ name: 'product.list', query: { cat: item.id } }"
				>
					<img :src="getImage(item)" />
					<h3 class="cataglo-slide-item-name">{{ item.name }}</h3>
				</router-link>
				<template #prev>
					<span class="nav-btn nav-btn-left">
						<i class="las la-angle-left"></i>
					</span>
				</template>
				<template #next>
					<span class="nav-btn nav-btn-right">
						<i class="las la-angle-right"></i>
					</span>
				</template>
			</carousel>
		</b-container>
	</div>
</template>

<script>
export default {
	data: () => ({
		items: [
			{
				name: 'Granola',
				image: 'produtos-granola',
				id: 5
			},
			{
				name: 'Temperos',
				image: 'produtos-temperos',
				id: 10
			},
			{
				name: 'Cereais',
				image: 'produtos-cereais',
				cereais: 3
			},
			{
				name: 'Chás especiais',
				image: 'produtos-chas-especiais',
				id: 3
			},
			{
				name: 'Sal de parrilla',
				image: 'produtos-sal',
				id: 23
			},
			{
				name: 'Chás em caixinha',
				image: 'produtos-chas-caixinha',
				id: 8
			}
		]
	}),
	methods: {
		getImage(item) {
			return require(`@/assets/images/${item.image}.png`)
		}
	}
}
</script>

<style lang="scss">
.catalog-slider {
	.catalog-slider-title {
		background-color: $primary;
		color: #fff;
		padding: 1.6rem;
		font-size: 2rem;
		text-align: center;
		margin: 0;
		a {
			color: inherit;
		}
	}

	.cataglo-slide-item {
		padding: 4rem;
		margin: 1rem;
		text-align: center;
		display: inline-block;
		color: $body-color;
	}

	.cataglo-slide-item-name {
		margin-top: 2rem;
	}

	.catalog-slider-box {
		position: relative;
		margin: 8rem 0;
		.nav-btn {
			position: absolute;
			top: 50%;
			font-size: 3rem;
			transform: translateY(-4rem);
			z-index: 99;
			cursor: pointer;

			&:hover {
				color: $primary;
			}
		}

		.nav-btn-left {
			left: 0;
		}
		.nav-btn-right {
			right: 0;
		}
	}

	@include media-breakpoint-down(sm) {
		.cataglo-slide-item {
			padding: 2rem;
		}
	}
}
</style>
