<template>
	<div id="app">
		<link rel="preconnect" href="https://fonts.googleapis.com" />
		<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
		<link
			href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
			rel="stylesheet"
		/>
		<base-header />

		<div class="main" id="main">
			<router-view />
		</div>

		<base-footer />

		<div class="loader" v-show="overlay">
			<span class="overlay"></span>
			<b-spinner label="Loading..."></b-spinner>
		</div>
	</div>
</template>

<script>
import BaseFooter from './components/BaseFooter.vue'
import BaseHeader from './components/BaseHeader.vue'

export default {
	components: { BaseHeader, BaseFooter },
	data: () => ({}),
	mounted() {
		this.$store.dispatch('getCart')
	},
	computed: {
		overlay() {
			return this.$store.getters.isLoading
		}
	}
}
</script>

<style lang="scss">
.loader {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	.overlay {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: #000;
		opacity: 0.4;
	}
}
</style>
