function loadLocaleMessages() {
    const locales = require.context('@/i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}

const MESSAGES = loadLocaleMessages()
const LANG = localStorage.getItem('lang')

export default function (key, text) {
    return MESSAGES[LANG][key] || text
    // const data = _.get(MESSAGES[lang], key)
    // return data
}
